import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-la-idea",
      "style": {
        "position": "relative"
      }
    }}>{`💡 La idea`}<a parentName="h1" {...{
        "href": "#-la-idea",
        "aria-label": " la idea permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Video src="https://www.youtube-nocookie.com/embed/gjwr-7PgpN8" mdxType="Video" />
    <h2 {...{
      "id": "¿cómo-encaja-en-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`¿Cómo encaja en Kernel?`}<a parentName="h2" {...{
        "href": "#%C2%BFc%C3%B3mo-encaja-en-kernel",
        "aria-label": "¿cómo encaja en kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Este video de Devcon 1 contiene muchas gemas de conocimiento sobre las ideas detrás de Ethereum; ¿por qué podría ser interesante e importante tener un entorno Turing completo compartido al que cualquiera pueda acceder y programar?; ¿y cuál era (y es) la visión? Es especialmente útil mirar hacia atrás y preguntar qué ideas originales hemos logrado implementar y dónde se puede mejorar todavía. Este video ha sido seleccionado para ayudarte a profundizar su comprensión (más técnica) de:`}</p>
    <Process mdxType="Process">
      <p>{`Valor`}</p>
      <p>{`Confianza `}</p>
      <p>{`Incentivos `}</p>
      <p>{`Resistencia a la censura `}</p>
      <p>{`(¡No solo!) dinero y discurso `}</p>
    </Process>
    <h2 {...{
      "id": "resumen",
      "style": {
        "position": "relative"
      }
    }}>{`Resumen`}<a parentName="h2" {...{
        "href": "#resumen",
        "aria-label": "resumen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Este video es la génesis de la analogía entre la calculadora (Bitcoin) y el teléfono inteligente (Ethereum) y
una demostración del poder de la generalidad...`}</p>
    <blockquote>
      <p parentName="blockquote">{`"En lugar de tener un protocolo diseñado en torno a un conjunto muy pequeño de casos de uso,
simplemente creas un `}<strong parentName="p">{`sistema operativo de propósito general`}</strong>{` y dejas que las personas construyan
lo que quieran como aplicaciones encima de él".`}</p>
    </blockquote>
    <p>{`Ethereum es un blockchain con algunas characterísticas únicas:`}</p>
    <ul>
      <li parentName="ul">{`Incorpora un lenguaje de programación Turing-completo: esencialmente un híbrido entre arquitecturas
de máquinas virtuales estándar, secuencias de comandos de Bitcoin y "algunas otras cosas". `}</li>
      <li parentName="ul">{`Todo el mundo puede escribir programas en este lenguaje o bien, lenguajes de alto nivel que se compilan a este,
tomar estos códigos compilados, ponerlos en transacciones y enviarlos al blockchain.
La transacción se confirma y obtiene una cuenta especial en esa dirección. `}</li>
      <li parentName="ul">{`Las cuentas contractuales y las cuentas de propiedad externa tienen `}<strong parentName="li">{`los mismos privilegios`}</strong>{`.`}</li>
      <li parentName="ul">{`Cualquier persona puede crear una aplicación, con las reglas que sea. Y cualquiera persona puede interactuar con esa
aplicación, es decir, puedes hacer NameCoin en 10 líneas de código...`}</li>
    </ul>
    <p>{`El estado se define como un valor clave que asigna direcciones a objetos de cuenta. Cada objeto de cuenta tiene un
"nonce" (un número o valor que solo se puede usar una vez) y un saldo. Las cuentas de contrato también incluyen un hash de código y almacenamiento usando un árbol trie. Luego,
Vitalik analiza cómo funcionan las transacciones con detalles importantes de bajo nivel para que podamos construir una
imagen conceptual completa en las próximas semanas. `}</p>
    <blockquote>
      <p parentName="blockquote">{`"En realidad, puedes pensar en Ethereum como un sistema bastante simple. Puedes pensar en el estado como
una base de datos, y puedes pensar en cada uno de estos contratos como programas que están
sentados en una computadora, `}<strong parentName="p">{`excepto la computadora se distribuye masivamente a nivel mundial. En realidad,
es una red altamente segura respaldada por decenas de miles de computadoras en todo el mundo.`}</strong>{` Es audaz
porque es importante".`}</p>
    </blockquote>
    <p>{`Sin embargo, esto plantea "El problema Halting". Si tienes un lenguaje de secuencias de comandos general, entonces, ¿qué impide que alguien escriba un programa que no finaliza (o se pare / halt), ocupando todos los recursos de la computadora e impidiendo que alguien más lo use? Vitalik explica cómo resuelve esto con "gas": una tarifa que se cobra por cada paso computacional que debe realizar la computadora, donde hay un límite de pasos computacionales posibles por bloque.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`P: El estado de Ethereum se define como un...?`}</p>
        <p>{`R: valor clave que asigna direcciones a objetos de cuenta.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "economía-virtual",
      "style": {
        "position": "relative"
      }
    }}>{`Economía Virtual`}<a parentName="h3" {...{
        "href": "#econom%C3%ADa-virtual",
        "aria-label": "economía virtual permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Esto significa que no hay un límite `}<em parentName="p">{`técnico`}</em>{` sobre lo que se puede poner en el campo `}<inlineCode parentName="p">{`datos`}</inlineCode>{`
de una transacción, pero hay uno `}<em parentName="p">{`económico`}</em>{`, ya que cuantos más datos incluyas, más caro
se vuelve. Fue un gas 68 adicional por cada byte de datos que incluyiste en ese momento, aunque
es posible que haya cambiado ahora.  `}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 Ejercicio para el lector: ¿cómo dirías cuál es el precio del gas por byte actualmente?`}</p>
    </blockquote>
    <p>{`Vitalik consiguientemente analiza algunas de las complejidades en torno a `}<inlineCode parentName="p">{`v`}</inlineCode>{`, `}<inlineCode parentName="p">{`r`}</inlineCode>{` y `}<inlineCode parentName="p">{`s`}</inlineCode>{` de la criptografía de curva
elíptica, y cómo `}<inlineCode parentName="p">{`v`}</inlineCode>{` es un campo adicional que se usa para la recuperación de claves públicas. También proporciona
más información sobre recibos y registros y por qué los registros son más baratos y permiten un acceso eficiente
de clientes ligeros. Aunque suene aburrido, este es el corazón de cómo construimos
herramientas resistentes a la censura.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`P: ¿Cómo evitamos que se pare un blockchain con un script completo de Turing?`}</p>
        <p>{`R: Cobrando "gas" por cada paso computacional y limitando la cantidad de gas consumido por bloque.`}</p>
      </Card>
    </Flash>
    <p>{`A continuación una descripción de la `}<strong parentName="p">{`Máquina Virtual Ethereum`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Stack: hasta 1024 campos de 32 bytes.`}</li>
      <li parentName="ul">{`Memoria: solo una matriz de bytes que se expande infinitamente, pero cuanto más expande la matriz de bytes,
más gas que tienes que pagar. `}<strong parentName="li">{`La mayoría de los límites no son estáticos, son económicos y verás
esta idea una y otra vez.`}</strong></li>
      <li parentName="ul">{`Almacenamiento: permanente para contratos. Puedes leer y escribir en él.`}</li>
      <li parentName="ul">{`Variables de entorno: la Máquina Virtual puede acceder al número del bloque, el tiempo, la dificultad de extracción, el bloque anterior
hash, etc.`}</li>
      <li parentName="ul">{`Registros: almacenamiento de sólo agregar en un bloque específico, no en el estado.`}</li>
      <li parentName="ul">{`Subllamada: código de operación por el cual Máquina Virtual puede llamar a otros contratos.`}</li>
    </ul>
    <p>{`Vitalik analiza la codificación ABI (interfaz binaria de aplicación) y RLP (prefijo de longitud recursiva) "para personas que
son fanáticos de la teoría de conjuntos". Puede parecer aburrido, pero es una demostración de la cultura primitiva y de cómo
piensan realmente las personas que construyeron todo esto. Continúa con una explicación de los algoritmos de memoria dura
para la minería y por qué fueron una innovación; enfatiza el tiempo de bloqueo rápido (17s); y analiza "tíos", que resuelven las tasas obsoletas de bloqueos que surgen debido a la latencia de la red. Vale la pena escucharlo por el contexto cultural, pero 23:50 - 28:57 ahora está un poco desactualizado.`}</p>
    <p>{`Vitalik destaca que los intentos de un árbol Merkle son "una construcción que permite pruebas compactas y
eficientemente verificables de que una transacción en particular se incluyó en un bloque en particular".
Sin embargo, no se trata solo de transacciones en el árbol Merkle; también es el estado: es decir, ese almacenamiento
árbol trie del que hablamos anteriormente en el objeto de la cuenta.`}</p>
    <p>{`Comprender cómo esta elección particular de estructura de datos nos permite expresar sucintamente
el estado compartido revela por qué la intimidad con los detalles técnicos de bajo nivel permite construir
una imagen conceptual integral de cómo se une todo y el tipo significados que la tecnología
puede crear. `}</p>
    <p>{`¿Qué tipo de significado exactamente? Bueno, `}<a parentName="p" {...{
        "href": "https://www.goodreads.com/book/show/5755.The_Language_Instinct"
      }}>{`el lenguaje en sí está compuesto de estructuras profundas en forma de árbol`}</a>{`. Son estos patrones combinatorios básicos, pero extraordinariamente poderosos, los que permiten a los seres humanos muy jóvenes aprender y utilizar un fenómeno casi infinitamente complejo con tanta facilidad, incluso cuando sus cerebros aún se están desarrollando. Más prosaicamente, cada palabra es un árbol, con sus raíces rastreando a través de un sinfín de otras palabras, idiomas y personas, y sus ramas alcanzando cualquier número de interpretaciones únicas en tiempos y contextos aún por descubrir. El lenguaje es el único sistema `}<a parentName="p" {...{
        "href": "https://medium.com/@VitalikButerin/the-meaning-of-decentralization-a0c92b76a274"
      }}>{`lógicamente descentralizado`}</a>{` que tenemos, por lo que tiene sentido que las estructuras de datos que informan los próximos sistemas lógicamente descentralizados que co-creamos reflejen eso en el corazón de cómo comunicamos valor actualmente.`}</p>
    <h3 {...{
      "id": "computación-generalizada",
      "style": {
        "position": "relative"
      }
    }}>{`Computación generalizada`}<a parentName="h3" {...{
        "href": "#computaci%C3%B3n-generalizada",
        "aria-label": "computación generalizada permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Si eres menos técnico que Vitalik (vamos, al igual que casi todos nosotros) y te preguntas qué significa todo esto
realmente. Bueno, el punto crítico es esta noción de un `}<strong parentName="p">{`sistema operativo de propósito general`}</strong>{`: una
máquina virtual monolítica distribuida en todo el mundo que es compartida por todos, que no es propiedad de
nadie y que no se puede apagar sin también apagar Internet. Con Bitcoin,
puedes transportar 12 palabras mágicas en su cabeza a través de cualquier frontera del
mundo, deletrearlas en una máquina conectada a Internet y tener acceso inmediato al valor. `}</p>
    <p>{`Ahora, existe la posibilidad de reducir no solo el valor monetario, sino
también la `}<em parentName="p">{`computación generalizada`}</em>{`. Es decir, su mnemónica de 12 o 24 palabras podría ser su sistema operativo personal completo,
al que se podría acceder desde cualquier lugar. Deja de pensar en las computadoras como una pantalla en la que escribe cosas desde un teclado.
Comienza a ver el mundo en sí mismo como un patio de juegos infinitamente programable, cuyo acceso solo requiere unas pocas palabras mágicas
que cualquier persona, en cualquier lugar, puede crear cuando aprende los conceptos básicos de un nuevo orden de lenguaje.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`P: Las 12 o 24 palabras que se utilizan para generar tu clave privada y otorgarle acceso a blockchain se denominan `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`.`}</p>
        <p>{`R: Mnemotécnicas.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "kaizen-y-el-principio-de-substracción",
      "style": {
        "position": "relative"
      }
    }}>{`Kaizen y el principio de substracción`}<a parentName="h3" {...{
        "href": "#kaizen-y-el-principio-de-substracci%C3%B3n",
        "aria-label": "kaizen y el principio de substracción permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`También vale la pena entender la `}<a parentName="p" {...{
        "href": "https://ethereum.foundation/philosophy/"
      }}>{`filosofía de la Fundación Ethereum`}</a>{`, ya que funciona con dos fascinantes opuestos complementarios.`}</p>
    <List mdxType="List">
      <Link to="https://youtu.be/R7FjX0GEiAM?t=110" mdxType="Link">
La charla por excelencia de DevCon IV
      </Link>
    </List>
    <p>{`El término 改善 (que se pronuncia "kaizen", significa "mejorar continuamente") fué usado por primera vez en Japón después de la Segunda Guerra Mundial, mayoritarimente promovido por negocios americanos y profesores de gestión de calidad, y encontró su apogeo en "The Toyota Way". Así que no es un término con una gran historia, pero es uno que podemos recuperar combinándolo con su opuesto, lo que Aya Miyaguchi llama "El principio de substracción". `}</p>
    <p>{`Arraigada en la filosofía Zen, la Fundación Ethereum tiene como objetivo "hacerse pequeña"; hacer al no hacer; y tratar de importar menos. Al restar consistentemente de su propia importancia e influencia, entonces la mejora continua que todos deseamos ver ocurre en la capacidad y habilidad de la comunidad. Es elegante y simple.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Buscamos mejorar continuamente a las personas que nos rodean, humillándonos y entregando poder en cada oportunidad, sin eludir la responsabilidad. `}</p>
    </blockquote>
    <p>{`Encantados, poco a poco nos encontramos sin hacer nada, siendo felices. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      